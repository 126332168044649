import { Meta, MetaProps } from "components/meta"
import { PreviewAlert } from "components/preview-alert"
import { Header, HeaderProps } from "components/header"
import { Footer } from "components/footer"
import { TailwindIndicator } from "components/tailwind-indicator"
import { Metadata } from 'next';
import { Analytics } from '@vercel/analytics/react';

export interface LayoutProps extends HeaderProps {
  meta?: MetaProps
  menus: HeaderProps["menus"]
  children?: React.ReactNode
}


export function Layout({ meta, menus, children }: LayoutProps) {
  
  return (
    <>
      <Meta {...meta} />
      <div className="w-full mx-auto">

        <PreviewAlert />
        <Header menus={{ main: menus.main }}/>
        <main className="max-w-screen-2xl mx-auto flex-1 pb-16">{children}        <Analytics /></main>
        <Footer  />
      </div>
      <TailwindIndicator />
    </>
  )
}
