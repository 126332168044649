import * as React from "react";
import Link from "next/link";
import { DrupalMenuLinkContent } from "next-drupal";
import classNames from "classnames";

import siteConfig from "site.config";
import { LocaleSwitcher } from "components/locale-switcher";
import { MenuMain } from "components/menu-main";
import { MenuMainmob } from "components/menu-main-mob";
import { FormSearch } from "components/form--search";
import Image from "next/image";

import { ChevronDownIcon } from "@heroicons/react/20/solid";

export interface HeaderProps {
  menus: {
    main: DrupalMenuLinkContent[];
  };
}

export function Header({ menus }: HeaderProps) {
  const [showMenu, setShowMenu] = React.useState<Boolean>(false);

  return (
    <header className="max-w-screen-2xl mx-auto container border-b border-white">
      {/* Desktop Header */}
      <div className="hidden xl:flex mx-auto">
        <div className="flex-start ml-0">
          <span className="sr-only">CHPV</span>
          <Link href="/" passHref className="flex justify-start">
              <Image
                src="/logo.svg"
                alt="logo"
                title="logo"
                width={300}
                height={150}
                layout="intrinsic"
                objectFit="contain" // Utilisation correcte pour ajuster l'image
                priority={true}
              />
              <span className="sr-only">{siteConfig.name}</span>
        
          </Link>
        </div>

        <div className="grow-0"></div>

        <div className="grow flex-end">
          <MenuMain items={menus.main} />
        </div>
      </div>

      {/* Mobile Header */}
      <MenuMainmob items={menus.main} />
    </header>
  );
}

export default Header;
