import Head from "next/head";
import { useRouter } from "next/router";

import siteConfig from "site.config";
import { absoluteURL2 } from "lib/utils";

export interface MetaProps {
  title?: string;
  description?: string;
  path?: string;
}

export function Meta({ title, description, path }: MetaProps) {
  const router = useRouter();

  return (
    <Head>
      {/* Canonical Link */}
      <link
        rel="canonical"
        href={absoluteURL2(path || (router.asPath !== "/" ? router.asPath : ""))}
      />

      {/* Title and Description */}
      <title>{`${title} | ${siteConfig.name}`}</title>
      <meta
        name="description"
        property="og:description"
        content={description || siteConfig.slogan}
      />

      {/* Open Graph Metadata */}
      <meta property="og:type" content="website" />
      <meta name="url" property="og:url" content={absoluteURL2(path || router.asPath)} />
      <meta name="title" property="og:title" content={title} />
      <meta name="content" property="og:content" content={description || title} />

      {/* Open Graph Image */}
      <meta
        name="image"
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/logo-chpv.png`}
      />
      <meta name="image:width" property="og:image:width" content="800" />
      <meta name="image:height" property="og:image:height" content="600" />
    </Head>
  );
}

export default Meta;
