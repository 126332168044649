import * as React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import siteConfig from "site.config";
import { NewsLetterSignUpForm } from "components/mailchimp";

export function Footer() {
  return (
    <footer className="bg-white px-2">
      <div className="container mx-auto grid grid-cols-1 divide-y">
        <div className="grid grid-cols-12 gap-0">
          <div className="col-span-6 sm:col-span-7 md:col-span-8 lg:col-span-4 text-left">
            <Link href="/" passHref className="flex justify-start pl-0 lg:pl-16">

                <Image src="/logo.svg" height={200} width={300} alt="logo" />
                <span className="sr-only">{siteConfig.name}</span>

            </Link>
          </div>

          <div className="col-span-6 break-all lg:hidden sm:col-span-5 md:col-span-4 gap-0 relative">
            <div className="pl-0 pt-8 md:pt-10 pb-10 text-sm">
              Association pour l&apos;étude de <br />
              l&apos;histoire de la politique de la Ville
              <br />
              Campus Condorcet <br />
              8 cours des humanités
              <br />
              93 300 Aubervilliers
              <br />
              comite@comitehistoirepolville.fr
            </div>
          </div>

          <div className="col-span-12 lg:col-span-5 gap-4 relative">
            <div className="mx-auto lg:absolute w-full pb-10 sm:pl-0 sm:pb-0 text-sm bottom-12">
              <NewsLetterSignUpForm />
            </div>
          </div>

          <div className="col-span-12 hidden lg:block sm:col-span-5 md:col-span-2 lg:col-span-3 gap-4 relative">
            <div className="sm:absolute break-all pl-24 pb-10 sm:pl-0 sm:pb-0 text-sm bottom-5">
              Association pour l&apos;étude de <br />
              l&apos;histoire de la politique de la Ville
              <br />
              Campus Condorcet <br />
              8 cours des humanités
              <br />
              93 300 Aubervilliers
              <br />
              comite@comitehistoirepolville.fr
            </div>
          </div>
        </div>
      </div>

      <div className="pt-10 pb-10">
        <div className="grid grid-cols-12 gap-0 container mx-auto">
          <div className="col-span-12 text-center sm:col-span-4 pb-6 lg:col-span-3">
            <Link href="https://agence-cohesion-territoires.gouv.fr/" target="_blank" className="flex justify-start" passHref>

                <Image src="/rp.svg" height={100} width={300} alt="logo république" />

            </Link>
          </div>

          <div className="col-span-12 text-center sm:pl-0 pb-6 sm:col-span-4 lg:col-span-3">
            <Link href="https://agence-cohesion-territoires.gouv.fr/" target="_blank" className="flex justify-start" passHref>
                <Image src="/anct.svg" height={100} width={300} alt="logo ANCT" />

            </Link>
          </div>

          <div className="col-span-12 text-center sm:pl-0 pb-6 sm:col-span-4 lg:col-span-3">
            <Link href="https://www.caissedesdepots.fr/" target="_blank" className="flex items-center" passHref>
                <Image src="/cdc.svg" height={100} width={200} alt="logo CDC" />

            </Link>
          </div>

          <div className="col-span-12 sm:pl-0 sm:col-span-2 md:col-span-3 lg:col-span-3 sm:relative">
            <Link href="https://www.campus-condorcet.fr/" target="_blank" className="flex justify-last" passHref>
                <Image src="/condorcet.svg" height={100} width={200} alt="logo condorcet" />

            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
