import { useRef, useState } from 'react';

export function NewsLetterSignUpForm() {
  const inputRef = useRef(null);

  const [message, setMessage] = useState("");



  const subscribeUser = async (e) => {
    e.preventDefault();

    // this is where your mailchimp request is made

    const res = await fetch('/api/subscribeUser', {
      body: JSON.stringify({
        email: inputRef.current.value,
      }),

      headers: {
        'Content-Type': 'application/json',
      },

      method: 'POST',
    });
    const { error } = await res.json();

    if (error) {
      // 4. If there was an error, update the message in state.
      setMessage(error);

      return;
    }

    // 5. Clear the input value and show a success message.
    inputRef.current.value = "";
    setMessage("Success! 🎉 ");
  };



  return (
    <form onSubmit={subscribeUser}>


      <input
        type="email"
        id="email-input"
        name="email"
        className="w-3/4 float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

        placeholder="Inscrivez-vous à notre newsletter"
        ref={inputRef}
        required
        autoCapitalize="off"
        autoCorrect="off"
      />



      <button type="submit"
      className="text-white w-1/4 ml-3 float-left bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
value="" name="subscribe">
        &gt;
      </button>
      <div className="inline-block w-full">
                {message}
              </div>
    </form>









  );
}
