import classNames from "classnames";
import { DrupalMenuLinkContent } from "next-drupal";
import Link from "next/link";
import { useRouter } from "next/router";
import { Search } from "components/search";

import * as React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

interface MenuMainProps {
  items: DrupalMenuLinkContent[];
}

export function MenuMain({ items, ...props }: MenuMainProps) {
  const router = useRouter();

  const isActiveLink = (url: string) => {
    return (
      router.asPath === url ||
      `/${router.locale}${router.asPath === "/" ? "" : router.asPath}` === url ||
      (url !== "/" ? router.asPath.indexOf(url) === 0 : false)
    );
  };

  return (
    <nav {...props}>
      <ul className="flex flex-col h-40 content-end items-center justify-center w-full pt-8 space-y-6 md:pt-0 md:space-y-0 md:flex-row md:space-x-4 lg:space-x-8 ml-auto">
        {items
          .filter((item) => !item.parent.includes("menu_link_content"))
          .map((item) => (
            <Menu as="div" key={item.id} className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full px-3 py-2 hover:bg-gray-50">
                  {item.expanded.toString() === "false" ? (
                    <Link
                      href={item.url}
                      passHref
                      className={classNames(
                        "sm:text-base md:text-2xl lg:text-2xl titre hover:text-gray-900",
                        {
                          "border-b-primary": isActiveLink(item.url),
                        }
                      )}
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <>
                      <span
                        className={classNames(
                          "sm:text-base md:text-2xl lg:text-2xl titre hover:text-gray-900",
                          {
                            "border-b-primary": isActiveLink(item.url),
                          }
                        )}
                      >
                        {item.title}
                      </span>
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 mt-2 text-gray-900"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Menu.Button>
              </div>

              {item.expanded.toString() === "true" && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {items
                      .filter((subItem) => subItem.parent.includes(item.id))
                      .map((subItem) => (
                        <Menu.Item key={subItem.id}>
                          {({ active }) => (
                            <a
                              href={subItem.url}
                              className={classNames(
                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {subItem.title}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                  </Menu.Items>
                </Transition>
              )}
            </Menu>
          ))}
      </ul>
      <Search />
    </nav>
  );
}

export default MenuMain;
