import siteConfig from "site.config"

export function truncate(value: string, length: number, suffix = "...") {
  if (value.length < length) {
    return value
  }

  return value.slice(0, length) + suffix
}

export function absoluteURL(uri: string) {
  return `${siteConfig.drupalBaseUrl}${uri}`
}

export function absoluteURL2(uri: string) {
  return `${siteConfig.publicUrl}${uri}`
}
export function formatDate(input: string): string {
  const date = new Date(input)
  return date.toLocaleDateString("fr-FR", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })
}

export function getDisplayURL(url) {
  const siteBaseURL = "https://comitehistoirepolville.fr";
  const path = new URL(url).pathname; // Extract the path after the domain
  return `${siteBaseURL}${path}`;
}



export function formatDate2(input: string): string {
  const date = new Date(input);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  
  const formattedDate2 = date.toLocaleDateString("fr-FR", options);
  const [day, month, year] = formattedDate2.split(" ");
  
  return `${day}<br/>${month}<br/>${year}`;
}


export function isRelative(url: string) {
  return !new RegExp("^(?:[a-z]+:)?//", "i").test(url)
}
