module.exports = {
  name: "Comité d histoire de la politique de la ville",
  slogan: "FAIRE L’HISTOIRE DE LA POLITIQUE DE LA VILLE AVEC LES ACTEURS, LES CHERCHEURS, LES TERRITOIRES.",
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  publicUrl: process.env.NEXT_PUBLIC_URL,

  drupalBaseUrl: process.env.NEXT_PUBLIC_DRUPAL_BASE_URL,
  defaultLocale: "fr",
  locales: {
    en: "English",
    fr: "Français",
  },
}
